/* eslint-disable max-lines */
export default [
    {
        path: 'OnboardingPages/ObTagsA',
        route: 'ob-tags-a',
    },
    {
        path: 'OnboardingPages/ObTagsB',
        route: 'ob-tags-b',
    },
    {
        path: 'OnboardingPages/ObWhatIsFastingA',
        route: 'ob-what-is-fasting-a',
    },
    {
        path: 'OnboardingPages/ObWhatIsFastingB',
        route: 'ob-what-is-fasting-b',
    },
    {
        path: 'OnboardingPages/ObBehaveChoiceSc',
        route: 'ob-behave-choice-sc',
    },
    {
        path: 'OnboardingPages/ObAgeFeedback',
        route: 'ob-age-feedback',
    },
    {
        path: 'OnboardingPages/ObBehaveEatingSc',
        route: 'ob-behave-eating-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveMultitaskingSc',
        route: 'ob-behave-multitasking-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveRoutinesSc',
        route: 'ob-behave-routines-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveOldHabitsSc',
        route: 'ob-behave-oldhabits-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveImpulseSc',
        route: 'ob-behave-impulse-sc',
    },
    {
        path: 'OnboardingPages/ObPaceGoal',
        route: 'ob-pace-goal',
    },
    {
        path: 'OnboardingPages/ObGoalSet',
        route: 'ob-goal-set',
    },
    {
        path: 'OnboardingPages/ObGoalSetA',
        route: 'ob-goal-set-a',
    },
    {
        path: 'OnboardingPages/ObGoalsOver',
        route: 'ob-goals',
    },
    {
        path: 'OnboardingPages/ObGoalsOld',
        route: 'ob-goals-old',
    },
    {
        path: 'OnboardingPages/ObGoalsMix',
        route: 'ob-goals-mix',
    },
    {
        path: 'OnboardingPages/ObGoalsConfig',
        route: 'ob-goals-config',
    },
    {
        path: 'OnboardingPages/ObGoalsMixA',
        route: 'ob-goals-mix-a',
    },
    {
        path: 'OnboardingPages/ObGoalsMixB',
        route: 'ob-goals-mix-b',
    },
    {
        path: 'OnboardingPages/ObPsychological',
        route: 'ob-psychological',
    },
    {
        path: 'OnboardingPages/CreatingD',
        route: 'creating-d',
    },
    {
        path: 'OnboardingPages/CreatingConfig',
        route: 'creating-config',
    },
    {
        path: 'OnboardingPages/ObCreating1',
        route: 'ob-creating-1',
    },
    {
        path: 'OnboardingPages/ObCreating2',
        route: 'ob-creating-2',
    },
    {
        path: 'OnboardingPages/ObCreating3',
        route: 'ob-creating-3',
    },
    {
        path: 'StartPages/StartAge',
        route: 'start-age',
    },
    {
        path: 'StartPages/StartAgeD',
        route: 'start-age-d',
    },
    {
        path: 'StartPages/StartAgeMix',
        route: 'start-age-mix',
    },
    {
        path: 'StartPages/StartAgeMixA',
        route: 'start-age-mix-a',
    },
    {
        path: 'StartPages/StartAgeMixConfig',
        route: 'start-age-mix-config',
    },
    {
        path: 'StartPages/StartUnisex',
        route: 'start-unisex',
    },
    {
        path: 'StartPages/Index',
        route: 'index',
    },
    {
        path: 'StartPages/StartArticle',
        route: 'start-article',
    },
    {
        path: 'StartPages/StartArticleA',
        route: 'start-article-a',
    },
    {
        path: 'StartPages/StartSimpleD',
        route: 'start-simple-d',
    },
    {
        path: 'StartPages/StartBlogger',
        route: 'start-blogger',
    },
    {
        path: 'StartPages/StartBloggerA',
        route: 'start-blogger-a',
    },
    {
        path: 'StartPages/StartSeniorA',
        route: 'start-senior-a',
    },
    {
        path: 'StartPages/StartSeniorB',
        route: 'start-senior-b',
    },
    {
        path: 'StartPages/StartSeniorC',
        route: 'start-senior-c',
    },
    {
        path: 'StartPages/StartPageChart',
        route: 'start-page-chart',
    },
    {
        path: 'StartPages/StartPageChartLong',
        route: 'start-page-chart-long',
    },
    {
        path: 'StartPages/StartFasting',
        route: 'start-fasting',
    },
    {
        path: 'StartPages/StartFastingA',
        route: 'start-fasting-a',
    },
    {
        path: 'StartPages/StartGoal',
        route: 'start-goal',
    },
    {
        path: 'StartPages/StartGoalA',
        route: 'start-goal-a',
    },
    {
        path: 'StartPages/StartGoalB',
        route: 'start-goal-b',
    },
    {
        path: 'StartPages/StartGoalC',
        route: 'start-goal-c',
    },
    {
        path: 'OnboardingPages/EmailConsent',
        route: 'email-consent',
    },
    {
        path: 'OnboardingPages/EmailConsentConfig',
        route: 'email-consent-config',
    },
    {
        path: 'PaymentLandingPages/Upsell',
        route: 'upsell',
    },
    {
        path: 'PaymentLandingPages/UpsellTwo',
        route: 'upsell-2',
    },
    {
        path: 'GeneralPages/VipSupport',
        route: 'vip-support',
    },
    {
        path: 'OnboardingPages/ObHeightA',
        route: 'ob-height-a',
    },
    {
        path: 'OnboardingPages/ObCurrentWeightA',
        route: 'ob-weight-current-a',
    },
    {
        path: 'OnboardingPages/ObCurrentWeightC',
        route: 'ob-weight-current-c',
    },
    {
        path: 'OnboardingPages/ObTargetWeightA',
        route: 'ob-weight-target-a',
    },
    {
        path: 'OnboardingPages/ObLooseWeightReason',
        route: 'ob-loose-weight-reason',
    },
    {
        path: 'OnboardingPages/ObLooseWeightReasonA',
        route: 'ob-loose-weight-reason-a',
    },
    {
        path: 'OnboardingPages/ObAgeA',
        route: 'ob-age-a',
    },
    {
        path: 'OnboardingPages/ObFastingAwareness',
        route: 'ob-fasting-awareness',
    },
    {
        path: 'OnboardingPages/ObAwarenessConfig',
        route: 'ob-awareness-config',
    },
    {
        path: 'OnboardingPages/CurrentBody',
        route: 'ob-body-type-current',
    },
    {
        path: 'OnboardingPages/CurrentBodyTypeMix',
        route: 'ob-body-type-current-mix',
    },
    {
        path: 'OnboardingPages/ObBodyTypeCurrentMixA',
        route: 'ob-body-type-current-mix-a',
    },
    {
        path: 'OnboardingPages/TargetBody',
        route: 'ob-body-type-target',
    },
    {
        path: 'OnboardingPages/TargetBodyTypeMix',
        route: 'ob-body-type-target-mix',
    },
    {
        path: 'OnboardingPages/ObBodyTypeTargetMixA',
        route: 'ob-body-type-target-mix-a',
    },
    {
        path: 'OnboardingPages/TargetZones',
        route: 'ob-target-zones',
    },
    {
        path: 'OnboardingPages/TargetZonesMix',
        route: 'ob-target-zones-mix',
    },
    {
        path: 'OnboardingPages/TargetZonesConfig',
        route: 'ob-target-zones-config',
    },
    {
        path: 'OnboardingPages/BreakfastTime',
        route: 'ob-time-breakfast',
    },
    {
        path: 'OnboardingPages/ObBadHabits',
        route: 'ob-bad-habits',
    },
    {
        path: 'OnboardingPages/LunchTime',
        route: 'ob-time-lunch',
    },
    {
        path: 'OnboardingPages/DinnerTime',
        route: 'ob-time-dinner',
    },
    {
        path: 'OnboardingPages/PreferredPlace',
        route: 'ob-pref-eat-place',
    },
    {
        path: 'OnboardingPages/FastingWeekend',
        route: 'ob-weekend',
    },
    {
        path: 'OnboardingPages/WorkoutSchedule',
        route: 'ob-workout',
    },
    {
        path: 'OnboardingPages/ObWorkoutA',
        route: 'ob-workout-a',
    },
    {
        path: 'OnboardingPages/ObWorkoutMix',
        route: 'ob-workout-mix',
    },
    {
        path: 'OnboardingPages/JobActive',
        route: 'ob-job-active',
    },
    {
        path: 'OnboardingPages/ObExercises',
        route: 'ob-exercises',
    },
    {
        path: 'OnboardingPages/ObExercisesFeedback',
        route: 'ob-exercises-feedback',
    },
    {
        path: 'OnboardingPages/FastingSleep',
        route: 'ob-sleep',
    },
    {
        path: 'OnboardingPages/FastingWater',
        route: 'ob-water',
    },
    {
        path: 'OnboardingPages/FastingMotivation',
        route: 'ob-motivation',
    },
    {
        path: 'OnboardingPages/ObMotivationConfig',
        route: 'ob-motivation-config',
    },
    {
        path: 'OnboardingPages/HappyWeight',
        route: 'ob-happy-weight',
    },
    {
        path: 'OnboardingPages/ObGender',
        route: 'ob-gender',
    },
    {
        path: 'OnboardingPages/ObAttempts',
        route: 'ob-attempts',
    },
    {
        path: 'OnboardingPages/ObHealthConditions',
        route: 'ob-health-conditions',
    },
    {
        path: 'OnboardingPages/ObGoal2',
        route: 'ob-goal-2',
    },
    {
        path: 'OnboardingPages/ObEmotions1',
        route: 'ob-emotions-1',
    },
    {
        path: 'OnboardingPages/ObEmotions2',
        route: 'ob-emotions-2',
    },
    {
        path: 'OnboardingPages/ObEmotions3',
        route: 'ob-emotions-3',
    },
    {
        path: 'OnboardingPages/ObEmotions4',
        route: 'ob-emotions-4',
    },
    {
        path: 'OnboardingPages/ObFastingIntervalA',
        route: 'ob-fasting-interval-a',
    },
    {
        path: 'OnboardingPages/ObFoodInFridge',
        route: 'ob-food-in-fridge',
    },
    {
        path: 'OnboardingPages/WorkloadType',
        route: 'ob-job-load',
    },
    {
        path: 'ResultPages/Result',
        route: 'result',
    },
    {
        path: 'ResultPages/ResultConfig',
        route: 'result-config',
    },
    {
        path: 'ResultPages/ResultC',
        route: 'result-c',
    },
    {
        path: 'ResultPages/ResultD',
        route: 'result-d',
    },
    {
        path: 'OnboardingPages/ObWeeklyEffect',
        route: 'ob-weekly-effect',
    },
    {
        path: 'OnboardingPages/ObWeeklyEffectA',
        route: 'ob-weekly-effect-a',
    },
    {
        path: 'OnboardingPages/ObWeeklyEffectB',
        route: 'ob-weekly-effect-b',
    },
    {
        path: 'OnboardingPages/ObStairs',
        route: 'ob-stairs',
    },
    {
        path: 'OnboardingPages/ObWalking',
        route: 'ob-walking',
    },
    {
        path: 'OnboardingPages/ObBackIssue',
        route: 'ob-back-issue',
    },
    {
        path: 'OnboardingPages/ObBackIssueConfig',
        route: 'ob-back-issue-config',
    },
    {
        path: 'OnboardingPages/ObMythsA',
        route: 'ob-myths-a',
    },
    {
        path: 'OnboardingPages/ObMythsB',
        route: 'ob-myths-b',
    },
    {
        path: 'OnboardingPages/ObMythsC',
        route: 'ob-myths-c',
    },
    {
        path: 'OnboardingPages/ObTargetZonesMixA',
        route: 'ob-target-zones-mix-a',
    },
    {
        path: 'OnboardingPages/ObTargetZonesMixB',
        route: 'ob-target-zones-mix-b',
    },
    {
        path: 'OnboardingPages/ObStatementFoodEmotions',
        route: 'ob-statement-food-emotions',
    },
    {
        path: 'OnboardingPages/ObStatementClearPlate',
        route: 'ob-statement-clear-plate',
    },
    {
        path: 'OnboardingPages/ObPregnant',
        route: 'ob-pregnant',
    },
    {
        path: 'OnboardingPages/ObMedications',
        route: 'ob-medications',
    },
    {
        path: 'OnboardingPages/ObUnfortunately',
        route: 'ob-unfortunately',
    },
    {
        path: 'OnboardingPages/ObFortunately',
        route: 'ob-fortunately',
    },
    {
        path: 'OnboardingPages/ObTempo',
        route: 'ob-tempo',
    },
    {
        path: 'OnboardingPages/ObOccasion',
        route: 'ob-occasion',
    },
    {
        path: 'OnboardingPages/ObOccasionResult',
        route: 'ob-occasion-result',
    },
    {
        path: 'OnboardingPages/ResultWeightOnlyA',
        route: 'result-weight-only-a',
    },
    {
        path: 'OnboardingPages/ResultWeightOnlyB',
        route: 'result-weight-only-b',
    },
    {
        path: 'OnboardingPages/ObTargetWeightB',
        route: 'ob-weight-target-b',
    },
    {
        path: 'OnboardingPages/ObTargetWeightC',
        route: 'ob-weight-target-c',
    },
    {
        path: 'OnboardingPages/ObTargetWeightConfirmation',
        route: 'ob-target-weight-confirmation',
    },
    {
        path: 'OnboardingPages/ObTargetWeightE',
        route: 'ob-weight-target-e',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE1Foxtrot4',
        route: 'payment-e1-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE1Foxtrot5',
        route: 'payment-e1-foxtrot5',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE3Foxtrot4',
        route: 'payment-e3-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE9Foxtrot12',
        route: 'payment-e9-foxtrot12',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE8Foxtrot12',
        route: 'payment-e8-foxtrot12',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG1Foxtrot1',
        route: 'payment-g1-foxtrot1',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG1Foxtrot12',
        route: 'payment-g1-foxtrot12',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG1Foxtrot18',
        route: 'payment-g1-foxtrot18',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG1Foxtrot15',
        route: 'payment-g1-foxtrot15',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG4Foxtrot16',
        route: 'payment-g4-foxtrot16',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG4Foxtrot16Config',
        route: 'payment-g4-foxtrot16-config',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG5Foxtrot16Config',
        route: 'payment-g5-foxtrot16-config',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA1Foxtrot1',
        route: 'offer-a1-foxtrot1',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA1Foxtrot12',
        route: 'offer-a1-foxtrot12',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA1Foxtrot18',
        route: 'offer-a1-foxtrot18',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA1Foxtrot15',
        route: 'offer-a1-foxtrot15',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA4Foxtrot16',
        route: 'offer-a4-foxtrot16',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA4Foxtrot16Config',
        route: 'offer-a4-foxtrot16-config',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA5Foxtrot16Config',
        route: 'offer-a5-foxtrot16-config',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferE1Foxtrot4',
        route: 'offer-e1-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferE1Foxtrot5',
        route: 'offer-e1-foxtrot5',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferE3Foxtrot4',
        route: 'offer-e3-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferE4Foxtrot4',
        route: 'offer-e4-foxtrot4',
    },
    {
        path: 'OnboardingPages/Email',
        route: 'email',
    },
    {
        path: 'OnboardingPages/EmailA',
        route: 'email-a',
    },
    {
        path: 'OnboardingPages/EmailB',
        route: 'email-b',
    },
    {
        path: 'OnboardingPages/EmailC',
        route: 'email-c',
    },
    {
        path: 'OnboardingPages/EmailD',
        route: 'email-d',
    },
    {
        path: 'OnboardingPages/EmailF',
        route: 'email-f',
    },
    {
        path: 'OnboardingPages/EmailAccount',
        route: 'email-account',
    },
    {
        path: 'OnboardingPages/EmailConfig',
        route: 'email-config',
    },
    {
        path: 'OnboardingPages/EmailConfigA',
        route: 'email-config-a',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofA',
        route: 'ob-social-proof-a',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofC',
        route: 'ob-social-proof-c',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofD',
        route: 'ob-social-proof-d',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofMix',
        route: 'ob-social-proof-mix',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofConfig',
        route: 'ob-social-proof-config',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofAges',
        route: 'ob-social-proof-ages',
    },
    {
        path: 'OnboardingPages/ObSocialFeedback/ObSocialFeedback',
        route: 'ob-social-feedback',
    },
    {
        path: 'OnboardingPages/FeedbackSummary',
        route: 'feedback-summary',
    },
    {
        path: 'OnboardingPages/FeedbackSummaryConfig',
        route: 'feedback-summary-config',
    },
    {
        path: 'OnboardingPages/ObFoodYellowList',
        route: 'ob-food-yellow-list',
    },
    {
        path: 'OnboardingPages/ObFoodGreenList',
        route: 'ob-food-green-list',
    },
    {
        path: 'OnboardingPages/ObFoodRedList',
        route: 'ob-food-red-list',
    },
    {
        path: 'OnboardingPages/ObFoodIntro',
        route: 'ob-food-intro',
    },
    {
        path: 'OnboardingPages/ObFoodRedInfo',
        route: 'ob-food-red-info',
    },
    {
        path: 'OnboardingPages/ObFoodGreenInfo',
        route: 'ob-food-green-info',
    },
    {
        path: 'OnboardingPages/ObFoodYellowInfo',
        route: 'ob-food-yellow-info',
    },
    {
        path: 'OnboardingPages/FoodResult',
        route: 'food-result',
    },
    {
        path: 'OnboardingPages/ObRestrictiveDiet',
        route: 'ob-restrictive-diet',
    },
    {
        path: 'OnboardingPages/ObCheatMeal',
        route: 'ob-cheat-meal',
    },
    {
        path: 'OnboardingPages/ObSkinTone',
        route: 'ob-skin-tone',
    },
    {
        path: 'OnboardingPages/ObFeelingsWeight',
        route: 'ob-feelings-weight',
    },
    {
        path: 'OnboardingPages/ObBodyTypeCurrentMorph',
        route: 'ob-body-type-current-morph',
    },
    {
        path: 'OnboardingPages/ObFeeling',
        route: 'ob-feeling',
    },
    {
        path: 'OnboardingPages/ObFeelingA',
        route: 'ob-feeling-a',
    },
    {
        path: 'OnboardingPages/ObFeelings',
        route: 'ob-feelings',
    },
    {
        path: 'OnboardingPages/ObFeelingsA',
        route: 'ob-feelings-a',
    },
    {
        path: 'OnboardingPages/ObFeelingsAFeedback',
        route: 'ob-feelings-a-feedback',
    },
    {
        path: 'OnboardingPages/ObTrackersFeedback',
        route: 'ob-trackers-feedback',
    },
    {
        path: 'OnboardingPages/ObFitnessTracker',
        route: 'ob-fitness-tracker',
    },
    {
        path: 'OnboardingPages/ObScale',
        route: 'ob-scale',
    },
    {
        path: 'OnboardingPages/ObYoyoDiets',
        route: 'ob-yoyo-diets',
    },
    {
        path: 'OnboardingPages/ObQuizStart',
        route: 'ob-quiz-start',
    },
    {
        path: 'OnboardingPages/ObHealthFeedback',
        route: 'ob-health-feedback',
    },
    {
        path: 'OnboardingPages/ObTrustFeedback',
        route: 'ob-trust-feedback',
    },
    {
        path: 'OnboardingPages/ObPacePlanA',
        route: 'ob-pace-plan-a',
    },
    {
        path: 'OnboardingPages/ObFinalFeedback',
        route: 'ob-final-feedback',
    },
    {
        path: 'OnboardingPages/ObFeedback1',
        route: 'ob-feedback-1',
    },
    {
        path: 'OnboardingPages/ObFeedback2',
        route: 'ob-feedback-2',
    },
    {
        path: 'OnboardingPages/ObDiets',
        route: 'ob-diets',
    },
    {
        path: 'OnboardingPages/ObMotivation1',
        route: 'ob-motivation-1',
    },
    {
        path: 'OnboardingPages/ObMotivation2',
        route: 'ob-motivation-2',
    },
    {
        path: 'OnboardingPages/ObMotivation3',
        route: 'ob-motivation-3',
    },
    {
        path: 'OnboardingPages/ObPriorities',
        route: 'ob-priorities',
    },
    {
        path: 'OnboardingPages/ObGoal1',
        route: 'ob-goal-1',
    },
    {
        path: 'OnboardingPages/ObSnackTriggers',
        route: 'ob-snack-triggers',
    },
    {
        path: 'OnboardingPages/ObSnack',
        route: 'ob-snack',
    },
    {
        path: 'OnboardingPages/ObExperts',
        route: 'ob-experts',
    },
    {
        path: 'OnboardingPages/ObDynamicWeightLoss1',
        route: 'ob-dynamic-weight-loss-1',
    },
    {
        path: 'OnboardingPages/ObDynamicWeightLoss2',
        route: 'ob-dynamic-weight-loss-2',
    },
    {
        path: 'OnboardingPages/ObDynamicWeightLoss3',
        route: 'ob-dynamic-weight-loss-3',
    },
    {
        path: 'OnboardingPages/ObDynamicWeightLoss4',
        route: 'ob-dynamic-weight-loss-4',
    },
    {
        path: 'OnboardingPages/ObWelcomeToFe',
        route: 'ob-welcome-to-fe',
    },
    {
        path: 'GeneralPages/Registration',
        route: 'registration-page',
    },
    {
        path: 'GeneralPages/ThankYou',
        route: 'thank-you',
    },
] as { path: string; route: string }[];
